










































































































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IContact, IMessage, ISenderID, PayloadState } from '@/types/types';
const groupModule = namespace('group');
const contactModule = namespace('contact');
const smsModule = namespace('sms');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import DialogMixins from '@/mixins/DialogMixins';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('max', {
  ...max,
  message: 'Maximum characters must be 11',
});

@Component({
  name: 'SendSMSToAllDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @Getter('getUseIntlRoute') useIntlRoute!: boolean;
  @groupModule.Getter('getResetFormValues') resetFormValues!: IMessage;
  @contactModule.Getter('getContactDetails') details!: IContact;
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;
  @Prop({ default: 0 }) totalSavedContacts!: number;
  @Prop({ default: 0 }) creditBalance!: number;

  sms: IMessage = {
    to: '',
    from: '',
    message: '',
    type: 'Flash',
  };
  hint = '';
  totalCreditNeeded = 0;

  @Watch('details')
  onContactDetailsChange(contact: IContact): void {
    this.sms.to = contact.phone;
  }

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.sms = { ...this.resetFormValues };
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Watch('sms.message')
  onMessageContentChange(content: string): void {
    this.totalCreditNeeded =
      Math.ceil(content.length / 160) * this.totalSavedContacts;
    this.hint = `${content.length} words / ${this.totalCreditNeeded} sms unit`;
  }

  @Emit('sendSMSToAll')
  sendSMS(): { body: IMessage; type?: string } {
    return { body: this.sms };
  }

  @Emit('actions')
  closeDialog(): PayloadState {
    return {
      idx: 'contacts',
      state: false,
    };
  }
}
